import React from 'react'
import Slider from 'react-slick';
import { useQuery } from '@apollo/client'
import { GET_PRODUCTS, getProductsCustomQueryVariables } from 'Queries'
import { path, cutTitleToLimit } from 'Utils'
import { ProductPrice, LazyLoadImage, Link } from 'Blocks'
import config from 'Config'
import { PAGE_CONTEXT as p } from 'Constants'

const TopProducts = props => {
  if(props.data.data.tpproducts.length === 0) return null;

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              centerPadding: '20px',
              slidesToShow: 2.5
            }
          },
          {
            breakpoint: 580,
            settings: {
              centerMode: true,
              centerPadding: '10px',
              slidesToShow: 1.15
            }
          }
        ]
    };

    const settingsV2 = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            centerPadding: '20px',
            slidesToShow: 2.5
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            centerPadding: '10px',
            slidesToShow: 1.50
          }
        }
      ]
  };
    
    const LoadURL = (type, uuid, slug) => {
        return path(type, { uuid, slug });
    }

    const LoadSliderV1 = () => {
      return (
        <div className={props.className}>
          <div className="content-slider-section product-slider-section">
              <div className="section-container-1140">
                  <div className="section-heading-vertical">{props.data.data.title}</div>
                  <Slider {...settings} className="section-slider-row slick-dotted">
                  {props.data.data.tpproducts && props.data.data.tpproducts.map((k) => {
                      let prices = {raw: k.price, list: 0 , currency: {
                        code: ''
                      }}
                      let isDiscounted = false

                      const outgoingLink = config.interStitialEnabled
                      ? path(p.OUTGOING, {
                          to: encodeURIComponent(k.affiliateUrl)
                        })
                      : affiliateUrl

                      const productPagelink = path('product', { uuid: k.uuid, slug: k.slug })

                      return (
                      <div className="slider-card" key={k.uuid}>
                        <div className="slider-card-image-block">
                          {k.theme !== '' &&
                            <div className="content-tag tag-blue">{k.theme}</div>
                          }
                          <Link
                            to={k.affiliateURL !== "" ? k.affiliateURL : productPagelink}
                            target={k.affiliateURL ? "_blank" : ""}
                          >
                            <LazyLoadImage
                              alt={k.title}
                              width={props.screenType === 'small' ? 243 : 272}
                              height={props.screenType === 'small' ? 302 : 338}
                              image={k.media}
                              className="global-image product-image"
                              useOriginal={true}
                            />
                          </Link>
                        </div>
                        <div className="slider-card-title">{cutTitleToLimit(k.title, 56)}</div>
                        <div className="slider-card-shop">{k.brand}</div>
                        <ProductPrice
                          className="slider-card-price"
                          {...{ isDiscounted, prices }}
                        />
                      </div>
                  )})}
                  </Slider>
              </div>
            </div>
        </div>
      )
    }

    const LoadSliderV2 = () => {
      return (
        <div className={props.className}>
          <div className="content-slider-section">
            <div className="section-container">
              <div className="content-slider-header">
                {props.data.data.title && props.data.data.title !== "" &&
                  <div className="content-slider-header-title">{props.data.data.title}</div>
                }

                {props.data.data.subTitle && props.data.data.subTitle !== "" &&
                  <div className="content-slider-header-description">{props.data.data.subTitle}</div>
                }
              </div>

              <Slider {...settingsV2} className="section-slider-row section-slider-row slick-dotted">
                {props.data.data.tpproducts && props.data.data.tpproducts.map((k) => {
                  let prices = {raw: k.price, list: 0 , currency: {
                    code: ''
                  }}
                  let isDiscounted = false

                  const outgoingLink = config.interStitialEnabled
                  ? path(p.OUTGOING, {
                      to: encodeURIComponent(k.affiliateUrl)
                    })
                  : affiliateUrl

                  const productPagelink = path('product', { uuid: k.uuid, slug: k.slug })
                  return (
                    <div className="slider-card" key={k.uuid}>
                      <div className="slider-card-image-block">
                        {k.theme &&
                          <div className="content-tag tag-orange">{k.theme}</div>
                        }
                        <Link
                          to={k.affiliateURL !== "" ? k.affiliateURL : productPagelink}
                          target={k.affiliateURL ? "_blank" : ""}
                        >
                          <LazyLoadImage
                            alt={k.title}
                            width={props.screenType === 'small' ? 243 : 278}
                            height={props.screenType === 'small' ? 302 : 338}
                            image={k.media}
                            className="global-image"
                            useOriginal={true}
                          />
                        </Link>
                      </div>
                      <div className="slider-card-title">{cutTitleToLimit(k.title, 56)}</div>
                      <div className="slider-card-description">{k.brand}</div>
                      <ProductPrice
                        className="slider-card-price-holder"
                        {...{ isDiscounted, prices }}
                      />
                    </div>
                )})}
              </Slider>
            </div>
          </div>
        </div>
      )
    }

    return config.name === 'sb' ? <LoadSliderV1 /> : <LoadSliderV2 />
}

export default TopProducts
