import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_CONTENTS, getArticlesCustomQueryVariables } from 'Queries'
import { LazyLoadImage } from 'Blocks'
import { path } from 'Utils'
import config from 'Config'

const LatestArticles = props => {
  const [uniqueArticles, setUniqueArticles] = useState([]);

  let nItems = [];
  const variables = getArticlesCustomQueryVariables(
    '',
    6,
    'article',
    false,
    '',
    props.data.data.articles,
    'no'
  )
  const { loading, error, data } = useQuery(GET_CONTENTS, { variables });

  const LoadURL = (type, uuid, slug) => {
    return path(type, { uuid, slug });
  }

  useEffect(() => {
    if(data) {
      const seenUuids = new Set();
      const unique = data.contents.contents.filter(content => {
        const isUnique = !seenUuids.has(content.uuid);
        if (isUnique) {
          seenUuids.add(content.uuid);
        }
        return isUnique;
      });
      setUniqueArticles(unique);
    }
  }, [data]);

  if(!data) return null;

  return (
    <div className={props.className}>
      <div className="articles-section news-section">
        <div className="section-container">
          <div className="articles-section-title">{props.data.data.title}</div>
          <div className="articles-section-description">{props.data.data.subTitle}</div>
          <div className="articles-content-section">
          {uniqueArticles.map((k) => (
            <div className="articles-content-block"  key={`latest-${k.uuid}`}>
              <div className="articles-content-image-block">
                <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">
                  <LazyLoadImage
                    alt={k.title.short}
                    width={props.screenType === 'small' ? 154 : 365}
                    height={props.screenType === 'small' ? 142.08 : 338}
                    image={k.image.main}
                    className="global-image"
                  />
                </Link>
              </div>
              <div className="articles-content-title-block">{k.title.short}</div>
              <div className="articles-content-description-block">{k.title.long}</div>
              <div className="articles-content-link-block">
                <Link to={LoadURL(k.type, k.uuid, k.slug)} className="primary-link articles-content-link">Read more</Link>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestArticles
